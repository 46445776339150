import habitat from "preact-habitat";
import App from "./App.js";

var id = 'appoint_space_forms_css';
if (!document.getElementById(id)) {
    var style = document.createElement("link");
    style.id = id;
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href = `https://embed.appoint.space/embed.css`;
    style.addEventListener('load', renderApp);
    document.head.appendChild(style);
} else {
    renderApp();
}

function renderApp() {
    habitat(App).render({
        selector: '.appoint-space-widget',
        clean: true,
    });
}

if (typeof window !== 'undefined') {
    window.__renderAppointSpaceForm = renderApp;
}