import clsx from '~/utils/clsx';
import { FancyInput as FancyInputCN } from '../fancy-input.css';
import { createRef, Component } from 'preact';
import { watchClassComponent } from '~/utils/hyperactiv';

export default watchClassComponent(class SingleLineTextInput extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.ref.current.focus();
    }, 2000); // todo make in dependant on animation duration
  }

  render(p) {
    return (
      <input class={clsx('FancyInput', FancyInputCN)} ref={this.ref} value={p.value} onInput={(e) => p.onChange(e.target.value)} placeholder={p.options.placeholder} />
    );
  }
})
