import get from '~/utils/get';
import set from '~/utils/set';

export function setState(observable, expandPath1, getState1, path, value) {
  const realPath = expandPath1(path);
  // console.debug(`[${realPath}] <= ${JSON.stringify((value), null, 2)}`);
  set(observable, realPath, value);
};

export function getState(observable, expandPath1, path) {
  if (!path) {
    return observable;
  } else {
    const realPath = expandPath1(path);
    const state = get(observable, realPath);
    // console.debug(`[${realPath}] =>`, state);
    return state;
  }
};

export function expandPath(thisPath, thisScreenPath, path) {
  var newVal = replaceInPath(path, 'this', thisPath);
  newVal = replaceInPath(newVal, 'thisScreen', thisScreenPath);
  return newVal;
}

function replaceInPath(path, from, to) {
  return path
    .split('.')
    .map((entry) => (entry === from ? to : entry))
    .join('.');
}

export function expandJson(thisPath, thisScreenPath, json) {
  const j1 = JSON.stringify(json).replace(/thisScreen\./g, thisScreenPath + '.');
  const j2 = j1.replace(/this\./g, thisPath + '.');
  const j3 = JSON.parse(j2);
  return j3;
}

export function unbox(boxedDynamicProps) {
  return Object.entries(boxedDynamicProps).reduce((acc, [prop, unbox]) => {
    acc[prop] = unbox();
    return acc;
  }, {});
}

export function toMethod(expandJson, jsonLogic, setState2, getState1) {
  return function self(thing) {

    switch (thing.type) {
      case 'set-state':
        return function (anyThing) {
          setState2(thing.target, anyThing);
        };
        break;
      case 'set-constant':
        return function () {
          setState2(thing.target, thing.value);
        };
        break;
      case 'logic':
        const getResult = () => {
          var expended = expandJson(thing.if);
          const result = jsonLogic.apply(expended, getState1());
          return result;
        };
        const execThen = thing.then ? self(thing.then) : noop;
        const execElse = thing.else ? self(thing.else) : noop;
        return function () {
          if (getResult()) {
            execThen();
          } else {
            execElse();
          }
        };
        break;
      default:
        throw new Error(`missing action type impl "${thing.type}"`);
    }
  };
}

function noop() { }
