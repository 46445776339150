import { watchFunctionalComponent } from '~/utils/hyperactiv';
import { LStickyMonths as LStickyMonthsCN } from './l-sticky-months.css';
import { MonthStickyRange as MonthStickyRangeCN } from './month-sticky-range.css';
import { Month as MonthCN } from './month.css';

export default watchFunctionalComponent(function VirtualMonthsRow(p) {
  var { reactor } = p;
  return (
    <div class={LStickyMonthsCN} style={{ width: `${reactor.totalWidth}px` }}>
      <div class={MonthStickyRangeCN} key={-1} style={{ width: `${reactor.padding}px` }}></div>
      {reactor.monthObjects.map((month) => (
        <div class={MonthStickyRangeCN} key={month.index} style={{ width: `${month.width}px` }}>
          <div class={MonthCN}>{month.text}</div>
        </div>
      ))}
    </div>
  )
});