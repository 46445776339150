import clsx from '~/utils/clsx.js';
import NextButton from '../next-button.js';
import { Confirmation as ConfirmationCN } from './confirmation.css';
import * as CssOptions from './confirmation.css';
import { Cancel as CancelCN } from './cancel.css';
import { CancelContainer as CancelContainerCN } from './cancel-container.css';
import { watchFunctionalComponent } from '~/utils/hyperactiv.js';
import Matter from '../../matter.js';

export default watchFunctionalComponent(function Confirmation(p) {
  var isVisible = p.tools.getSchemaAt(p.options.isVisibleDataSource);

  var extra = maybeMaterializeExtra();
  return (
    <div class={clsx('Confirmation', ConfirmationCN, CssOptions[p.options.style], !isVisible && 'isHidden')}>
      <div>
        {extra}
        {p.options.text ? <div>{p.options.text}</div> : null}
        <NextButton onClick={p.onClick} text={p.tools.T('Next')} />
      </div>

      {p.onCancel ? <div class={clsx("CancelContainer", CancelContainerCN)}>
        <span class={clsx('Cancel', CancelCN)} onClick={p.onCancel}>×</span>
      </div> : null}
    </div>
  )

  function maybeMaterializeExtra() {
    if (p.options.Extra) {
      var materialized = p.tools.materialize(p.options.Extra);
      return <Matter materialized={materialized} />
    }

    return null
  }
});
