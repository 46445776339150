import { watchFunctionalComponent } from '~/utils/hyperactiv';
import { Microlabel as MicrolabelCN } from '../microlabel.css'
import { OutputDisplay as OutputDisplayCN } from '../output-display.css'

export default watchFunctionalComponent(function TextInputSummary(p) {
  return (
    <div>
      {p.options.label ? <div class={MicrolabelCN}>{p.options.label}</div> : null}
      <div class={OutputDisplayCN}>{p.reactor.text}</div>
    </div>
  )
});