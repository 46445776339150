import jsonLogic from 'json-logic-js';
import { groupBy } from '~/utils';
import get from '~/utils/get';
import set from '~/utils/set';
import { expandPath, expandJson, getState, setState, toMethod as makeMethod, unbox } from './logic';
import componentMap from '../c/map';
import translations from '~/translations';

const DefaultComponent = (props) => <div> {JSON.stringify(props, null, 2)} </div>;

export default function materialize(
  schema,
  pathToComponent,
  pathToScreen,
  componentSchema,
) {
  const expandPath1 = expandPath.bind(null, pathToComponent, pathToScreen);
  const expandJson1 = expandJson.bind(null, pathToComponent, pathToScreen);
  const getState1 = getState.bind(null, schema, expandPath1);
  const setState1 = setState.bind(null, schema, expandPath1, getState1);
  const makeMethod1 = makeMethod(expandJson1, jsonLogic, setState1, getState1);

  const Component = componentMap[componentSchema.type] || DefaultComponent;
  let handlers = Object.entries(groupBy(componentSchema.handlers || [], 'prop')).reduce((acc, [prop, phs]) => {
    const methods = phs.map((ph) => {
      return makeMethod1(ph);
    });
    acc[prop] = function () {
      methods.forEach((m) => m.apply(null, arguments));
    };
    return acc;
  }, {});

  return {
    Component,
    materializedProps: {
      reactor: componentSchema.reactorFrom ? getState1(componentSchema.reactorFrom) : componentSchema.reactor,
      options: componentSchema.options || {},
      ...handlers,
      ...(componentSchema.props || {}),
      tools: {
        schema,
        getSchemaAt: getState1,
        makeExpandPath: (pathToComponent, pathToScreen) => expandPath.bind(null, pathToComponent, pathToScreen),
        makeGetSchema: (expandPath) => getState.bind(null, schema, expandPath),
        materialize: materialize.bind(null, schema, pathToComponent, pathToScreen),
        T: function (text) {
          return get(translations[text], schema.locale || 'pl', text);
        }
      }
    },
  };

}
