import { watchFunctionalComponent } from '~/utils/hyperactiv';
import { Microlabel as MicrolabelCN } from '../microlabel.css';
import { OutputDisplay as OutputDisplayCN } from '../output-display.css'

export default watchFunctionalComponent(function SingleChoiceSummary(p) {
    return (
        <div class="SingleChoiceSummary">
            {p.options.label ? <div class={MicrolabelCN}>{p.options.label}</div> : null}
            {p.reactor.options.filter(o => p.reactor.reactor.selectedId === o.id).map((o, i) => (
                <div class={OutputDisplayCN}>{o.text}</div>
            ))}
        </div>
    )
});