import dayjs from 'dayjs';
import api from '~/utils/api';

export function visibleDayIndexRange(daysCount, dayWidth, daysCountOverscan, maxInCache = 60) {
  const toItemFIdx = (px) => px / dayWidth;
  const thisClamp = (v) => clamp(v, 0, daysCount);
  let cache = null;
  const up = Math.ceil(maxInCache / 2);
  const down = Math.ceil(maxInCache / 4);
  return function (scrollLeft, visibleWidth) {
    // console.count(`sl -> ${scrollLeft}, vw -> ${visibleWidth}`);
    const startRenderIdx = thisClamp(Math.ceil(toItemFIdx(scrollLeft)) - daysCountOverscan);
    const endRenderIdx = thisClamp(Math.floor(toItemFIdx(scrollLeft + visibleWidth)) + daysCountOverscan);

    const range = [startRenderIdx, endRenderIdx];
    // console.count(`range[${range[0]},${range[1]}]`);
    if (cache) {
      // jezeli wyjebalo poza skale
      if (range[0] > cache[1] || range[1] < cache[0]) {
        cache = null;
      }
    }

    if (!cache) {
      cache = [range[0], range[1]];
      cache[1] += up;
      cache[0] -= down;
    } else if (range[1] > cache[1]) {
      cache[1] += up;
      cache[0] = cache[1] - maxInCache;
    } else if (range[0] < cache[0]) {
      cache[0] -= up;
      cache[1] = cache[0] + maxInCache;
    }

    if (cache[0] > range[0]) {
      cache[0] = range[0];
    }
    if (cache[1] < range[1]) {
      cache[1] = range[1];
    }

    cache = cache.map(thisClamp);
    // console.count(`cache[${cache[0]},${cache[1]}]`);
    return cache;
  };
}

export function clamp(number, boundOne, boundTwo) {
  if (!boundTwo) {
    return Math.max(number, boundOne) === boundOne ? number : boundOne;
  } else if (Math.min(number, boundOne) === number) {
    return boundOne;
  } else if (Math.max(number, boundTwo) === number) {
    return boundTwo;
  }
  return number;
}

export function range(fromInclusive, toExclusive) {
  return Array.from({ length: toExclusive - fromInclusive }, (_, i) => fromInclusive + i);
}

export function rangeMap(fromInclusive, toExclusive, mapFn) {
  const results = [];
  for (let i = fromInclusive; i < toExclusive; i++) {
    results.push(mapFn(i));
  }
  return results;
}

export function decodeMonthIndex(absoluteMonthIdx) {
  const monthIdx = absoluteMonthIdx % 12;
  const yearIdx = (absoluteMonthIdx - monthIdx) / 12;
  const monthNumber = monthIdx + 1;
  const yearNumber = yearIdx + 1;

  return {
    monthIdx,
    yearIdx,
    monthNumber,
    yearNumber,
  };
}

export const EPOCH_DAY_SPAN = 60 * 60 * 24;

export const DEFAULT_SELECTED = {
  // nesting for mobx
  slot: null,
};

export function monthIndexToDayJs(monthIndex) {
  const { monthNumber, yearNumber } = decodeMonthIndex(monthIndex);
  // console.log(`decoded ${monthIndex} to ${yearNumber} ${monthNumber}`);
  return dayjs(new Date(yearNumber, monthNumber - 1));
}

export async function fetchSlots(url, serviceId, epochRange) {
  const slots = await api.request({
    url: url,
    qs: {
      serviceId,
      start: epochRange[0],
      end: epochRange[1],
    }
  })
  return slots;
}

export function slotsToDaysMap(slots) {
  var days = {};

  var prevDay;
  slots.forEach((slot) => {
    var m = moment.unix(slot[0]);
    const mStartOfDay = m.clone().startOf('day');
    const dayStartEpoch = mStartOfDay.unix();

    const makeSlot = (slot) => {
      const s = {
        id: slot[0],
        text: m.format('H:mm'),
        value: slot[0],
        isChecked: false,
      };
      return s;
    };

    if (days[dayStartEpoch]) {
      days[dayStartEpoch].slots.push(makeSlot(slot));
    } else {
      const day = {
        id: dayStartEpoch,
        dayStartEpoch: dayStartEpoch,
        friendlyDayName: m.format('dddd'),
        dayNumberString: m.format('D'),
        monthName: m.format('MMMM'),
        isWeekend: [6, 0].includes(m.day()),
        slots: [makeSlot(slot)],
      };
      days[dayStartEpoch] = day;

      if (prevDay) {
        var m = moment.unix(prevDay.dayStartEpoch);
        while (true) {
          m.add(1, 'day');
          if (m.unix() > day.dayStartEpoch) {
            break;
          }

          days[m.unix()] = {
            id: m.unix(),
            dayStartEpoch: m.unix(),
            friendlyDayName: m.format('dddd'),
            dayNumberString: m.format('D'),
            monthName: m.format('MMMM'),
            isWeekend: [6, 0].includes(m.day()),
            slots: [],
          };
        }

        // let secondsDiff = day.dayStartEpoch - prevDay.dayStartEpoch;
        // while (true) {
        //   if (secondsDiff <= 0) {
        //     break;
        //   }

        //   if()
        // }
      }

      prevDay = day;
    }
  });

  return days;
}

export function displayLimitToDayJs(obj) {
  switch (obj.type) {
    case 'relative-to-now': {
      return dayjs().add(obj.count, obj.unit);
      break;
    }
    default:
      throw new Error(`unsupported display limit type "${obj.type}"`);
  }
}

export function rangesEqual(r1, r2) {
  return r1[0] === r2[0] && r1[1] === r2[1];
}
