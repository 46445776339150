import clsx from '~/utils/clsx';
import { watchFunctionalComponent } from '~/utils/hyperactiv';
import template from '~/utils/template';
import { Description as DescriptionCN } from './description.css';

export default watchFunctionalComponent(function Description(p) {

    if (p.options.substitute) {
        var text = template(p.options.text, p.tools.schema);
    } else {
        var text = p.options.text;
    }

    return (
        <div class={clsx('Description', DescriptionCN, p.cn)} dangerouslySetInnerHTML={{ __html: text }} />
    );
})