import get from '/utils/get';
import * as dayjs from 'dayjs';
import { Strong as StrongCN } from './strong.css';
import { Microlabel as MicrolabelCN } from '../../microlabel.css';
import { OutputDisplay as OutputDisplayCN } from '../../output-display.css';
import { Component } from 'preact';
import { watchClassComponent } from '~/utils/hyperactiv';

export default watchClassComponent(class Summary extends Component {
  render(p) {
    var epoch = get(p.reactor, 'selected.id');
    const m = dayjs.unix(epoch).locale(get(p, 'tools.schema.locale', 'pl')); // TODO dehardcode
    const a = m.format(`dddd,`);
    const b = m.format(`HH:mm`);
    const c = m.format(`D MMMM YYYY`);

    if (p.hideOnEmpty && !m.isValid()) {
      return null;
    }

    return (
      <div class="time-slot-picker-summary">
        {p.options.label ? <div class={MicrolabelCN}>{p.options.label}</div> : null}
        <div class={OutputDisplayCN} >
          {m.isValid() ? (
            <span>
              {a} <span class={StrongCN}>{b}</span> {c}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
});

