import api from "./api";
// import jsoner from '../../jsoner';

export default async function getSchema(slug) {
  if (slug) {
    console.debug(`decoding slug [${slug}]`);
    var split = slug.split('/');
  } else {
    console.debug(`decoding URL [${window.location}]`);
    let url = new URL(window.location);
    var split = url.pathname.split('/');
  }
  var service = split.pop();
  var workspace = split.pop();

  // return jsoner;

  var schema = await api.request({
    url: 'public/schemas',
    qs: {
      service,
      workspace,
    }
  })
  return schema;
}

// dlaczego wszystko w JSON
// dlatego ze przy czytaniu JSON i odtwarzaniu w interfejsie bedzie latwo zparsowac i odtworzyc z tego GUI
// jezeli tam by byl gdzies kod JS to sprsowanie byloby trudniejsze
// zawsze pozniej tez mozna dodac type: "function"

function fake() {
  return {
    "version": "1",
    "init": [
      {
        "type": "action",
        "action": "set-constant",
        "target": "screenId",
        "value": "1",
      }
    ],
    "screens": {
      "1": {
        "id": "1",
        "components": [
          {
            "kind": "title",
            "reactor": {
              "text": "Wizyta w laboratorium Santelab",
            },
          },
          {
            "kind": "description",
            "reactor": {
              "text": "Wybierz dla kogo będzie wizyta",
            },
          },
          {
            "id": "ab-choice",
            "kind": "single-choice",
            "reactor": {
              "options": [
                {
                  "id": "a",
                  "text": "Osoba dorosła"
                },
                {
                  "id": "b",
                  "text": "Dziecko do 5 roku życia"
                }
              ]
            },
            "dynamicProps": [
              {
                "prop": "selectedId",
                "source": "this.selectedId"
              }
            ],
            "propHandlers": [
              {
                "init": null,
                "prop": "onSelected",
                "type": "action",
                "action": "set-state",
                "target": "this.selectedId"
              },
              {
                "prop": "onUnselected",
                "type": "action",
                "value": null,
                "action": "set-constant",
                "target": "this.selectedId"
              }
            ],
            "submit": {
              "id": {
                "source": "this.selectedId",
                "description": "Wizyta dla",
              },
            },
          },
          {
            "kind": "confirmation",
            "dontCenter": true,
            "dynamicProps": [
              {
                "prop": "isVisible",
                "source": "thisScreen.ab-choice.selectedId"
              }
            ],
            "propHandlers": [
              {
                "prop": "onClick",
                "type": "logic",
                "if": {
                  "===": [
                    {
                      "var": "thisScreen.ab-choice.selectedId"
                    },
                    "b"
                  ]
                },
                "then": {
                  "type": "action",
                  "value": "screen-dziecko",
                  "action": "set-constant",
                  "target": "screenId"
                },
              },
              {
                "if": {
                  "===": [
                    {
                      "var": "thisScreen.ab-choice.selectedId"
                    },
                    "a"
                  ]
                },
                "prop": "onClick",
                "then": {
                  "type": "action",
                  "value": "screen-time-slot-picker",
                  "action": "set-constant",
                  "target": "screenId"
                },
                "type": "logic"
              }
            ]
          }
        ]
      },
      "screen-dziecko": {
        "id": "screen-dziecko",
        "components": [
          {
            "kind": "title",
            "reactor": {
              "text": "Dzieci do 5 roku życia",
            },
          },
          {
            "kind": "description",
            "reactor": {
              "text": "W wypadku dzieci do 5 roku życia prosimy o osobiste umówienie wizyty pod numerem telefonu 509 799 388",
            },
          }
        ]
      },
      "screen-time-slot-picker": {
        "id": "screen-time-slot-picker",
        "components": [
          {
            "kind": "title",
            "reactor": {
              "text": "Czas wizyty",
            },
          },
          {
            "kind": "description",
            "reactor": {
              "text": "Wybierz godzinę o której chciałbyś przyjść na badania",
            },
          },
          {
            "id": "time-slot-picker",
            "kind": "time-slot-picker",
            "reactor": {
              "url": "slots",
              "serviceId": "1",
            },
            "submit": {
              "id": {
                "description": "Czas wizyty",
                "source": "thisScreen.time-slot-picker.reactor.selected.id",
              }
            }
          },
          {
            "kind": "confirmation",
            "dontCenter": true,
            "dynamicProps": [
              {
                "prop": "isVisible",
                "source": "thisScreen.time-slot-picker.reactor.selected.isChecked"
              },
              {
                "prop": "Extra",
                "type": "component",
                "component": {
                  "kind": "time-slot-picker-summary",
                  "props": {
                    "hideOnEmpty": false
                  },
                  "inherit": "screen-time-slot-picker.time-slot-picker"
                }
              }
            ],
            "propHandlers": [
              {
                "prop": "onClick",
                "type": "action",
                "value": "screen-rodzaj-badan",
                "action": "set-constant",
                "target": "screenId"
              },
              {
                "prop": "onCancel",

                "type": "action",
                "action": "set-constant",

                "target": "thisScreen.time-slot-picker.reactor.selected.isChecked",
                "value": false,
              }
            ]
          }
        ],
        "persistent": true
      },
      "screen-rodzaj-badan": {
        "id": "screen-rodzaj-badan",
        "components": [
          {
            "kind": "title",
            "reactor": {
              "text": "Rodzaj badań",
            },
          },
          {
            "kind": "description",
            "reactor": {
              "text": "Wpisz mniej-więcej na jakie badania chcesz przyjść",
            },
          },
          {
            "id": "input",
            "kind": "single-line-text-input",
            "reactor": {
              "placeholder": "Wpisz tutaj..."
            },
            "dynamicProps": [
              {
                "prop": "value",
                "source": "this.text",
                "default": ""
              }
            ],
            "propHandlers": [
              {
                "prop": "onChange",
                "type": "action",
                "action": "set-state",
                "target": "this.text"
              }
            ],
            "submit": {
              "text": {
                "description": "Rodzaj badań",
                "source": "this.text"
              }
            }
          },
          {
            "id": "validator",
            "kind": "validator",
            "reactor": {
              "isBlank": {
                "type": "string",
                "maxLength": 0,
                "minLength": 0
              },
              "isValid": {
                "type": "string",
                "minLength": 3,
                "invalidMessage": "Wpisz co najmniej 3 znaki",
                "requiredMessage": "Koniecznie coś tutaj wpisz"
              },
              "debounce": 1000,
            },
            "dynamicProps": [
              {
                "prop": "data",
                "source": "thisScreen.input.text"
              }
            ],
          },
          {
            "kind": "confirmation",
            "dontCenter": true,
            "dynamicProps": [
              {
                "prop": "isVisible",
                "source": "thisScreen.validator.reactor.isCorrect"
              }
            ],
            "propHandlers": [
              {
                "prop": "onClick",
                "type": "action",
                "value": "tel",
                "action": "set-constant",
                "target": "screenId"
              }
            ]
          }
        ]
      },
      "tel": {
        "id": "tel",
        "components": [
          {
            "kind": "title",
            "reactor": {
              "text": "Numer telefonu",
            },
          },
          {
            "kind": "description",
            "reactor": {
              "text": "Podaj numer telefonu, na który wyślemy przypomnienie o wizycie",
            },
          },
          {
            "id": "phone",
            "kind": "phone-number-input",
            "dynamicProps": [
              {
                "prop": "inputValue",
                "source": "this.obj.inputValue",
                "default": "",
              },
              {
                "prop": "countryCode",
                "source": "this.obj.countryCode",
                "default": "pl"
              }
            ],
            "propHandlers": [
              {
                "prop": "onChange",
                "type": "action",
                "action": "set-state",
                "target": "this.obj"
              }
            ],
            "submit": {
              "id": {
                "source": "this.obj.phoneNumber",
                "description": "Numer telefonu",
              },
            },
          },
          {
            "id": "validator",
            "kind": "validator",
            "reactor": {
              "isBlank": {
                "type": "string",
                "maxLength": 0,
                "minLength": 0
              },
              "isValid": {
                "type": "string",
                "minLength": 5,
                "invalidMessage": "Wpisz nr telefonu",
                "requiredMessage": "Koniecznie coś tutaj wpisz"
              },
              "debounce": 1000,
            },
            "dynamicProps": [
              {
                "prop": "data",
                "source": "thisScreen.phone.obj.inputValue"
              }
            ],
          },
          {
            "kind": "confirmation",
            "dontCenter": true,
            "dynamicProps": [
              {
                "prop": "isVisible",
                "source": "thisScreen.validator.reactor.isCorrect"
              }
            ],
            "propHandlers": [
              {
                "prop": "onClick",
                "type": "action",
                "value": "submit",
                "action": "set-constant",
                "target": "screenId"
              }
            ]
          }
        ]
      },
      "submit": {
        "id": "submit",
        "components": [
          {
            "kind": "single-choice-summary",
            "props": {
              "label": "Wizyta w laboratorium"
            },
            "inherit": "1.ab-choice"
          },
          {
            "kind": "time-slot-picker-summary",
            "props": {
              "label": "Czas i data wizyty"
            },
            "inherit": "screen-time-slot-picker.time-slot-picker"
          },
          {
            "kind": "text-input-summary",
            "props": {
              "label": "Rodzaj badań"
            },
            "inherit": "screen-rodzaj-badan.input"
          },
          {
            "kind": "phone-number-input-summary",
            "props": {
              "label": "Numer telefonu"
            },
            "inherit": "tel.phone"
          },
          {
            "kind": "submit",
            "props": {
              "url": "submit",
              "serviceId": "1"
            },
            "dontCenter": true,
            "dynamicProps": [
              {
                "prop": "data",
                "source": ""
              },
              {
                "isSchema": true,
                "prop": "schema",
                "source": "",
              }
            ],
            "propHandlers": []
          }
        ]
      },
      "summary": {
        "id": "summary",
        "components": [
          {
            "kind": "title",
            "reactor": {
              "text": "Zarezerwowano",
            },
          },
          {
            "kind": "description",
            "reactor": {
              "text": "Twój kod to #{{code}} do podania przy rejestracji.\nWysłaliśmy SMS na numer {{tel.phone.obj.inputValue}}, na który później dostaniesz przypomnienie o wizycie.\n",
              "substitute": true,
            },
            "dynamicProps": [
              {
                "prop": "data",
                "source": ""
              },
              {
                "isSchema": true,
                "prop": "schema",
                "source": "",
              }
            ],
          },
          {
            "kind": "single-choice-summary",
            "props": {
              "label": "Wizyta w laboratorium"
            },
            "inherit": "1.ab-choice"
          },
          {
            "kind": "time-slot-picker-summary",
            "props": {
              "label": "Czas i data wizyty"
            },
            "inherit": "screen-time-slot-picker.time-slot-picker"
          },
          {
            "kind": "text-input-summary",
            "props": {
              "label": "Rodzaj badań"
            },
            "inherit": "screen-rodzaj-badan.input"
          },
          {
            "kind": "phone-number-input-summary",
            "props": {
              "label": "Numer telefonu"
            },
            "inherit": "tel.phone"
          },
        ]
      }
    }
  }
}