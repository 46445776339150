import countryCodeEmojiFlags from '~/generated/country-code-mapped-emoji-flags/data.json';
import countryCodeMappedTelephoneData from '~/generated/country-code-mapped-telephone-data/data.json';

export function getCountryDescriptors() {
  const groupedByCountryCode = Object.entries(countryCodeMappedTelephoneData).reduce((acc, [countryCode, d]) => {
    // descriptors.length > 1 already validated during generation

    d.name || raise('missing country name');
    d.format = d.format ? d.format + ' ' : '';
    d.dialCode = d.dialCode || '';

    acc[countryCode] = {
      code: countryCode,
      name: d.name,
      emoji: getEmojiFlagForCountryCode(countryCode),
      phoneNumberFormat: d.format,
      countryLocalPhoneNumberFormat: countryPhoneNumberFormatToLocal(d.format, d.dialCode),
      countryDialInCode: d.dialCode,
    };
    return acc;
  }, {});

  return groupedByCountryCode;
}

function raise(msg) {
  throw new Error(msg);
}

function countryPhoneNumberFormatToLocal(format, diallInCode) {
  const dialInCodeLength = diallInCode.length;
  let cutStart = nthIndex(format, '.', dialInCodeLength + 1);
  if (format[cutStart - 1] === '(') {
    cutStart--;
  }

  const localFormat = format.substring(cutStart);
  return localFormat;
}

function nthIndex(str, pat, n) {
  var L = str.length;
  var i = -1;

  while (n-- && i++ < L) {
    i = str.indexOf(pat, i);
    if (i < 0) break;
  }
  return i;
}

export const MISSING_COUNTRY_FLAG = '🏳';

function getEmojiFlagForCountryCode(countryCode) {
  return countryCodeEmojiFlags[countryCode] || MISSING_COUNTRY_FLAG;
}

export const defaultCountryDescriptors = getCountryDescriptors();
