export default function set(obj, path, value) {
  if (obj === undefined || obj === null) {
    throw new Error(`obj must have a value, i.e. must not be undefined or null`);
  }

  const sections = path.split('.');

  let workingObj = obj;
  for (let i = 0; i < sections.length - 1; i++) {
    const section = sections[i];

    if (workingObj[section] !== undefined && workingObj[section] !== null) {
      workingObj = workingObj[section];
      continue;
    } else {
      workingObj[section] = {};
      workingObj = workingObj[section];
      continue;
    }
  }

  workingObj[sections[sections.length - 1]] = value;
  
  return value;
}