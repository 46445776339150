export default function get(obj, path, defaultValue = undefined) {
  const sections = Array.isArray(path) ? path : path.split('.');

  let returnValue = obj;
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];

    if (returnValue !== undefined && returnValue !== null &&
      /* for the sake of observability we need to actually access the properties \/, hasOwnProperty is not suitable here */
      returnValue[section] !== undefined && returnValue[section] !== null) {
      returnValue = returnValue[section];
    } else {
      returnValue = undefined;
      break;
    }
  }

  return returnValue === undefined ? defaultValue : returnValue;
}