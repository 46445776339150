import { Cell as CellCN } from './cell.css';
import { Letter as LetterCN } from './letter.css'
import { Button as ButtonCN, checked as checkedCN } from './button.css'
import { watchFunctionalComponent } from '~/utils/hyperactiv';

export default watchFunctionalComponent(function Slot(p) {
  return (
    <div class={CellCN}>
      <button class={`${ButtonCN} ${p.reactor.isChecked ? checkedCN : ''}`} onClick={p.reactor.onClick}>
        {p.reactor.text.split('').map((letter, idx) => (
          <span class={LetterCN} key={idx}>
            {letter}
          </span>
        ))}
      </button>
    </div>
  )
})