/*
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import qs from 'querystring';
import parse from './parse';
import format from '../third_party/format';

const slashedProtocols = /https?|ftp|gopher|file/;

export default function(urlObj) {
  if (typeof urlObj === 'string') {
    urlObj = parse(urlObj);
  }

  const { protocol, host, pathname, search, hash } = format(
    urlObj,
    qs,
    slashedProtocols
  );

  return `${protocol}${host}${pathname}${search}${hash}`;
}
