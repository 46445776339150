import { createRef, Component } from 'preact';
import { watchClassComponent } from "~/utils/hyperactiv";

import { defaultCountryDescriptors, MISSING_COUNTRY_FLAG } from './utils';

import { Container as ContainerCN } from './simple/container.css';
import { CountrySelect as CountrySelectCN } from './simple/country-select.css';
import { FlagSelectorContainer as FlagSelectorContainerCN } from './simple/flag-selector-container.css';
import { Chevron as ChevronCN } from './simple/chevron.css';
import { FancyInput as FancyInputCN } from '../fancy-input.css';

export function phoneNumberInputFactory(
  getPlaceholderForCountryCode,
  formatPhoneNumberForCountryCode,
  getEmojiForCountryCode,
  getCountryDescriptors,
) {
  var CountrySelector = watchClassComponent(class extends Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }

    render() {
      const flagEmoji = getEmojiForCountryCode(this.props.options.countryCode);
      return (
        <span class={FlagSelectorContainerCN}>
          <span>{flagEmoji}</span>
          <span class={ChevronCN}>{'⌄'}</span>
          <select class={CountrySelectCN} onChange={this.handleChange} value={this.props.options.countryCode}>
            <option value=''>International phone number</option>
            {getCountryDescriptors().map(d => (
              <option key={d.code} value={d.code}>
                {d.name} {d.emoji} (+{d.countryDialInCode})
              </option>
            ))}
          </select>
        </span>
      );
    }

    handleChange(event) {
      this.props.options.countryCode = event.target.value;
    }
  })

  var PhoneNumberInput = watchClassComponent(class extends Component {
    constructor(props) {
      super(props);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this);

      this.inputRef = createRef();
    }

    render() {
      return (
        <div class={ContainerCN}>
          <CountrySelector options={this.props.options} countryCode={this.props.options.countryCode} onChange={this.handleCountryCodeChange} />
          <input
            class={FancyInputCN}
            ref={this.inputRef}
            placeholder={getPlaceholderForCountryCode(this.props.options.countryCode)}
            onInput={this.handleInputChange}
            value={this.props.options.inputValue}
            type="tel"
          />
        </div>
      );
    }

    handleCountryCodeChange(countryCode) {
      const formatted = formatPhoneNumberForCountryCode(countryCode, this.props.options.inputValue);

      const dialCode = defaultCountryDescriptors[countryCode].countryDialInCode;
      const localPhoneNumber = convertStringToRawPhoneNumber(formatted);
      const phoneNumber = `${dialCode ? `+${dialCode} ` : ''}${localPhoneNumber}`;
      this.props.options.countryCode = countryCode;
      this.props.options.inputValue = formatted;
      this.props.options.localPhoneNumber = localPhoneNumber;
      this.props.reactor.phoneNumber = phoneNumber;
      // force update even if user typed a string into number input. otherwise it doesnt update because "" === ""
      this.setState({});
    }

    handleInputChange(event) {
      // TODO handle backspace on "-" -> remove digit on left
      const value = event.target.value;
      const formatted = formatPhoneNumberForCountryCode(this.props.options.countryCode, value);

      const dialCode = defaultCountryDescriptors[this.props.options.countryCode].countryDialInCode;
      const localPhoneNumber = convertStringToRawPhoneNumber(formatted);
      const phoneNumber = `${dialCode ? `+${dialCode} ` : ''}${localPhoneNumber}`;
      // this.props.onChange({
      //   // countryCode: this.props.countryCode,
      //   // inputValue: formatted,
      //   localPhoneNumber: convertStringToRawPhoneNumber(formatted),
      //   phoneNumber,
      // });
      // this.props.options.countryCode = countryCode;
      this.props.options.inputValue = formatted;
      this.props.options.localPhoneNumber = convertStringToRawPhoneNumber(formatted);
      this.props.reactor.phoneNumber = phoneNumber;
      // force update even if user typed a string into number input. otherwise it doesnt update because "" === ""
      this.setState({});
    }

    // hack to prevent cursor jumping when editing beginning of the text
    getSnapshotBeforeUpdate(prevProps, prevState) {
      var node = this.inputRef.current;
      if (!node) {
        return null;
      }

      var oldLength = node.value.length;
      var oldIdx = node.selectionStart;
      var newIdx = Math.max(0, this.props.options.inputValue.length - oldLength + oldIdx);
      return { idx: newIdx };
    }
    // hack continued
    componentDidUpdate(prevProps, prevState, snapshot) {
      if (snapshot === null) {
        return;
      }

      var node = this.inputRef.current;
      node.selectionStart = node.selectionEnd = snapshot.idx;
    }
  })

  // PhoneNumberInput.defaultProps = {
  //   countryCode: '',
  //   inputValue: '',
  //   onChange: () => { },
  // };

  return PhoneNumberInput;
}

const DefaultPhoneNumberInput = phoneNumberInputFactory(
  getPlaceholderForCountryCode,
  formatPhoneNumberForCountryCode,
  getEmojiForCountryCode,
  getCountryDescriptors,
);
export default DefaultPhoneNumberInput;

function getPlaceholderForCountryCode(countryCode) {
  const descriptor = defaultCountryDescriptors[countryCode];
  // console.log(`country descriptor`, JSON.stringify(descriptor, null, 2));
  if (!descriptor) {
    return null;
  }

  const zeroed = descriptor.countryLocalPhoneNumberFormat.replace(/\./g, '0');
  // console.debug(`placeholder`, `"${zeroed}"`);
  return zeroed;
}

function convertStringToRawPhoneNumber(s) {
  const validPhoneNumberCharacters = s.replace(/[^0-9+#*]/g, '');
  return validPhoneNumberCharacters;
}

function formatPhoneNumberForCountryCode(countryCode, value) {
  const descriptor = defaultCountryDescriptors[countryCode];
  if (!descriptor) {
    return value;
  }

  const format = descriptor.countryLocalPhoneNumberFormat;
  const digitsOnly = value.replace(/[^0-9]/g, '');

  let formatted = '';
  let digitIdx = 0;
  for (let i = 0; i < format.length; i++) {
    const digit = digitsOnly[digitIdx];
    if (digit === undefined) {
      break;
    }

    const f = format[i];
    if (f === '.') {
      formatted += digit;
    } else {
      formatted += f;
      continue;
    }

    digitIdx++;
  }
  const remainder = digitsOnly.substring(digitIdx);
  const withRemainder = formatted + remainder;

  return withRemainder;
}

export function getEmojiForCountryCode(countryCode) {
  const descriptor = defaultCountryDescriptors[countryCode];
  if (!descriptor) {
    return MISSING_COUNTRY_FLAG;
  }

  return descriptor.emoji;
}

function getCountryDescriptors() {
  return Object.values(defaultCountryDescriptors);
}
