import clsx from '~/utils/clsx.js';
import { watchFunctionalComponent } from '~/utils/hyperactiv.js';
import SlotColumn from './slot-column.js';
import { VirtualSlotsRow as VirtualSlotsRowCN } from './virtual-slots-row.css';

export default watchFunctionalComponent(function VirtualSlotsRow(p) {
  return (
    <div class={VirtualSlotsRowCN} style={{ width: `${p.reactor.totalWidth}px` }}>
      <div class='prefix' style={{ width: p.reactor.padding + 'px' }}></div>
      {p.reactor.slotColumns.map((slotColumn) => (
        <SlotColumn key={slotColumn.id} reactor={slotColumn} />
      ))}
    </div>
  )
});