import { Screen as ScreenCN } from './screen.css';
import * as ScreenCNs from './screen.css';
import materialize from './logic/materialize';
import Matter from './matter';
import { SCREEN_STATE } from './logic/screen-manager';
import clsx from '~/utils/clsx';
import { watchFunctionalComponent } from '~/utils/hyperactiv';
import get from '~/utils/get';

const stateToClassName = {
    [SCREEN_STATE.TOP]: 'next',
    [SCREEN_STATE.CENTER]: 'current',
    [SCREEN_STATE.BOTTOM]: 'prev',
    [SCREEN_STATE.PRE_RENDERED]: 'isHidden',
}

export default watchFunctionalComponent(function Screen(props) {
    const { id: thisScreenId, state: thisScreenState, schema } = props;
    // const materialize2 = materialize.bind(null, );

    return (
        <div class={clsx(ScreenCN, ScreenCNs[stateToClassName[thisScreenState]])} data-screen-id={`${thisScreenId}`} data-state={`${thisScreenState}`}>
            {schema.screens[thisScreenId].componentsOrder.map(cId => {
                var pathToComponent = `screens.${thisScreenId}.components.${cId}`;
                var pathToScreen = `screens.${thisScreenId}.components`;
                var componentSchema = get(schema, pathToComponent);
                if (!componentSchema) {
                    debugger;
                }
                var materialized = materialize(
                    schema,
                    pathToComponent,
                    pathToScreen,
                    componentSchema,
                );

                return <Matter key={pathToComponent} materialized={materialized} />
            })}
        </div>
    )
})