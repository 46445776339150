import { Component } from 'preact';
import { watchClassComponent } from '~/utils/hyperactiv';
import { Loader as LoaderCN } from './loader.css';
import Slot from './slot.js';

export default watchClassComponent(class SlotContent extends Component {
  render(p) {
    if (p.reactor.loading) {
      return <div class={LoaderCN} key={'loader'}>loading...</div>;
    }

    if (p.reactor.error) {
      var messages = [
        `check your internet connection`,
      ]
      if (Array.isArray(p.reactor.error)) {
        messages = [...messages, ...(p.reactor.error.map(e => e.message || e))];
      } else {
        messages.push(p.reactor.error.message || p.reactor.error);
      }


      return (
        <div class='Error' key={'error'}>
          {messages.map(m => <div>{m}</div>)}
        </div>
      );
    }

    if (p.reactor.slots) {
      return p.reactor.slots.map((slot) => <Slot key={slot.id} reactor={slot} />);
    }
  }
})