// https://github.com/matthewmueller/number-to-letter/blob/master/index.js

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const base = alphabet.length;

export default function numberToLetter(n) {
  var digits = [];

  do {
    var v = n % base;
    digits.push(v);
    n = Math.floor(n / base);
  } while (n-- > 0);

  var chars = [];
  while (digits.length) {
    chars.push(alphabet[digits.pop()]);
  }

  return chars.join('');
};