import { watchFunctionalComponent } from '~/utils/hyperactiv';
import { Column as ColumnCN } from './column.css';
import SlotContent from './slot-content.js';

export default watchFunctionalComponent(function SlotColumn(p) {
  return (
    <div class={ColumnCN}>
      <SlotContent reactor={p.reactor} />
    </div>
  )
})
