export function delay(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export const shallowEqual = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => obj1[key] === obj2[key]);

export function minLength(min) {
  return function (str) {
    return isString(str) && str.length >= min;
  }
}

export function groupBy(arr, key) {
  var grouped = arr.reduce((r, v, i, a, k = v[key]) => ((r[k] || (r[k] = [])).push(v), r), {});
  return grouped;
}

export function stringify(v) {
  if (v == null) {
    return null;
  }

  return JSON.stringify(v, null, 2);
}

export function isString(str) {
  if (str && typeof str.valueOf() === "string") {
      return true
  }
  return false
}
