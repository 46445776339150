// https://github.com/lukeed/clsx/blob/master/src/index.js

export default function clsx() {
  var i = 0, tmp, x, str = '';
  while (i < arguments.length) {
    if (tmp = arguments[i++]) {
      if (x = tmp) {
        str && (str += ' ');
        str += x
      }
    }
  }
  return str;
}