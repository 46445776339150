import { Component } from 'preact';
import api from '~/utils/api.js';
import get from '~/utils/get.js';
import { observe, watchClassComponent } from '~/utils/hyperactiv.js';
import set from '~/utils/set.js';
import NextButton from '../next-button.js';

export default watchClassComponent(class Submit extends Component {

  constructor(p) {
    super(p);
    this.handleClick = this.handleClick.bind(this);
    this.reactor = observe({
      state: 'idle',
    })
  }

  render() {
    return (
      <div class="Submit">
        <NextButton onClick={this.handleClick} disabled={this.reactor.state === 'loading'} text={this.reactor.state === 'loading' ? '...' : this.props.tools.T('Book')} />
      </div>
    )
  }

  async handleClick() {
    var submission = {};
    var schema = this.props.tools.schema;
    Object.entries(schema.screens).map(([screenId, screen]) => {
      Object.entries(screen.components).map(([componentId, component]) => {
        if (component.reactor) {
          var expandPath = this.props.tools.makeExpandPath(
            `screens.${screenId}.components.${componentId}`,
            `screens.${screenId}.components`,
          );
          var getSchema = this.props.tools.makeGetSchema(expandPath);

          var value = getSchema(`screens.${screenId}.components.${componentId}.reactor`);
          set(submission, `screens.${screenId}.components.${componentId}.reactor`, value);
        }
      })
    })

    this.reactor.state = 'loading';
    api.request({
      method: 'POST',
      url: this.props.options.url,
      qs: {
        serviceId: this.props.options.serviceId,
      },
      body: submission,
    }).then(schemaToMerge => {
      schema.response = schemaToMerge.response;
      schema.screenId = 'summary';
    }).catch(error => {
      this.props.options.error = error.message;
    }).finally(() => {
      this.reactor.state = 'idle';
    })
  }
});
