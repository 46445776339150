import { VirtualDaysRow as VirtualDaysRowCN } from './virtual-days-row.css';
import Day from './day.js';
import clsx from '~/utils/clsx';
import { watchFunctionalComponent } from '~/utils/hyperactiv';

export default watchFunctionalComponent(function VirtualDaysRow(p) {
  return (
    <div class={VirtualDaysRowCN} style={{ width: `${p.reactor.totalWidth}px` }}>
      <div class='prefix' style={{ width: p.reactor.padding + 'px' }}></div>
      {p.reactor.dayObjects.map((day) => (
        <Day key={day.id} reactor={day} />
      ))}
    </div>
  )
});