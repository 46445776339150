import { watchFunctionalComponent } from "~/utils/hyperactiv"

export default watchFunctionalComponent(function DayHeader(p) {
  return (
    <span>
      <div>{p.reactor.friendlyDayName}</div>
      <div>{p.reactor.dayNumberString}</div>
      {/* <div >{p.reactor.monthName}</div> */}
    </span>
  )
})