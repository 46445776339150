import clsx from '~/utils/clsx.js';
import { getEmojiForCountryCode } from './phone-number-input.js';
import { FlagSelectorContainer as FlagSelectorContainerCN, disabled as disabledCN } from './simple/flag-selector-container.css';
import { PhoneNumber as PhoneNumberCN } from './summary.css';
import { OutputDisplay as OutputDisplayCN } from '../output-display.css';
import { Microlabel as MicrolabelCN } from '../microlabel.css';
import { watchFunctionalComponent } from '~/utils/hyperactiv.js';
import get from '~/utils/get.js';

export default watchFunctionalComponent(function PhoneInputSummary(props) {
  var options = get(props.tools.schema, `${props.options.source}.options`, '');
  return (
    <div class={clsx("PhoneNumberInputSummary")}>
      {props.options.label ? <div class={clsx("Microlabel", MicrolabelCN)}>{props.options.label}</div> : null}
      <div class={clsx("OutputDisplay", OutputDisplayCN)}>
        <div class={`${FlagSelectorContainerCN} ${disabledCN}`}>
          <div>{getEmojiForCountryCode(options.countryCode)}</div>
        </div>

        <span class={clsx('PhoneNumber', PhoneNumberCN)}>{options.inputValue}</span>
      </div>
    </div>
  );
});