import nou from '~/utils/nou';

export default function applyDefaults(options = {}, defaults = {}) {
  if (nou(options) || nou(defaults)) {
    throw new Error();
  }

  Object.keys(defaults).forEach(function (key) {
    const type = typeof options[key];
    if (type === 'undefined') {
      options[key] = defaults[key];
    } else if (type === 'object') {
      defaults(options[key], defaults[key])
    }
  });
};

