import { visibleDayIndexRange, displayLimitToDayJs, monthIndexToDayJs, range, rangeMap, clamp, rangesEqual, fetchSlots } from './utils';
import makeLinearScale from './utils/linear-scale';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import 'dayjs/locale/en-gb';
import applyDefaults from './utils/apply-defaults';
import get from '/utils/get';
import { observe } from '~/utils/hyperactiv'

const runInAction = (f) => f()

function State(reactor, reactorReactor, p) {
  const defaults = {
    url: 'http://10.55.0.90:20001/api/v1/slots?serviceId=1',
    displayLimits: {
      begin: { type: 'relative-to-now', unit: 'days', count: 0 },
      end: { type: 'relative-to-now', unit: 'years', count: 50 },
    },
    rendering: {
      daysOverscan: 4, //4,
      daysCache: 60, // 60
      columnWidth: 190, // this is fixed in css TODO make it variable
    },
    onSelected: (slot) => { },
    onUnselected: () => { },
    selected: null,
    locale: get(p, 'tools.schema.locale', 'pl'),
  };

  applyDefaults(reactor, defaults);

  const absoluteRange = [displayLimitToDayJs(reactor.displayLimits.begin).startOf('day'), displayLimitToDayJs(reactor.displayLimits.end).startOf('day')];
  const daysCount = absoluteRange[1].diff(absoluteRange[0], 'days');
  const lastDayIndex = daysCount - 1;
  // const dayIndexToEpoch = makeLinearScale(
  //   [0, lastDayIndex],
  //   [absoluteRange[0].unix(), absoluteRange[1].add(-1, 'day').unix()]
  // );
  const dayIndexToDayJs = function (dayIndex) {
    return absoluteRange[0].add(dayIndex, 'days');
  }
  const totalWidth = daysCount * reactor.rendering.columnWidth;
  const columnWidth = reactor.rendering.columnWidth;
  const epochToScrollLeft = makeLinearScale([0, lastDayIndex], [0, totalWidth]);
  const getDaysRange = visibleDayIndexRange(daysCount, reactor.rendering.columnWidth, reactor.rendering.daysOverscan, reactor.rendering.daysCache);
  const slotColumnsCache = {};

  Object.assign(reactor, {
    daysCount,
    lastDayIndex,
    totalWidth,
    daysRange: [],
    columnWidth,
    quickGoToDropdown: {
      options: monthSelectOptions(),
    },
    virtualMonthsRow: {
      totalWidth,
      padding: 0,
      monthObjects: [],
    },
    virtualDaysRow: {
      totalWidth,
      columnWidth,
      padding: 0,
      dayObjects: [],
    },
    virtualSlotsRow: {
      totalWidth,
      columnWidth,
      padding: 0,
      slotColumns: [],
    },
  });

  _handleScroll(reactor)(0, 500);
  reactor.handleScroll = _handleScroll(reactor);;
  return;

  function _handleScroll(reactor) {
    return function (scrollLeft, offsetWidth) {
      const daysRange = getDaysRange(scrollLeft, offsetWidth);
      // console.log(`[daysRange] <=`, JSON.stringify(daysRange));
      if (rangesEqual(daysRange, reactor.daysRange)) {
        // console.log(`NOT UPDATING because ranges are equal`, JSON.stringify(daysRange), JSON.stringify(reactor.daysRange))
        return;
      }
      // console.log(`!UPDATING because ranges are unequal`, JSON.stringify(daysRange), JSON.stringify(reactor.daysRange))
      reactor.daysRange = daysRange;
      // console.debug(`[reactor.daysRange] <=`, JSON.stringify(daysRange))
      const monthsRange = dayRangeToMonthRange(daysRange);

      {
        // * virtualMonthsRow * padding
        const firstDay = absoluteRange[0];
        const daysSE = [monthIndexToDayJs(monthsRange[0]).startOf('day'), monthIndexToDayJs(monthsRange[1]).startOf('day')];
        const firstMonthsDayInRange = daysSE[0];
        const daysDiff = firstMonthsDayInRange.diff(firstDay, 'days');
        const padding = Math.max(daysDiff * reactor.columnWidth, 0);
        // console.log(`days diff from start = ${daysDiff} -> ${padding}px`)
        reactor.virtualMonthsRow.padding = padding;
      }

      {
        // * virtualMonthsRow * monthObjects
        const firstDay = absoluteRange[0];
        const lastDay = absoluteRange[1];
        const monthObjects = rangeMap(monthsRange[0], monthsRange[1], (monthIndex) => {
          const d = monthIndexToDayJs(monthIndex).locale(reactor.locale);
          const monthString = d.format('MMMM YYYY');

          let monthWidth = d.daysInMonth() * reactor.columnWidth;
          if (d.month() === firstDay.month() && d.year() === firstDay.year()) {
            monthWidth -= firstDay.diff(d, 'days') * reactor.columnWidth;
          }
          if (d.month() === lastDay.month() && d.year() === lastDay.year()) {
            monthWidth -= (d.daysInMonth() - lastDay.diff(d, 'days')) * reactor.columnWidth;
          }
          // console.log(`${monthString} -> ${monthWidth / reactor.columnWidth} days -> ${monthWidth}px`)
          return {
            index: monthIndex,
            width: monthWidth,
            text: monthString,
          };
        });
        reactor.virtualMonthsRow.monthObjects = monthObjects;
      }

      {
        // * virtualDaysRow * padding
        const padding = reactor.columnWidth * daysRange[0];
        reactor.virtualDaysRow.padding = padding;
      }

      {
        // * virtualDaysRow * dayObjects
        const dayObjects = rangeMap(daysRange[0], daysRange[1], (dayIndex) => {
          const day = makeDay(dayIndex);
          return day;
        });
        reactor.virtualDaysRow.dayObjects = dayObjects;
      }

      {
        // * virtualSlotsRow * padding
        const padding = reactor.columnWidth * daysRange[0];
        reactor.virtualSlotsRow.padding = padding;
      }

      {
        // * virtualSlotsRow * slotColumns
        const slotColumns = rangeMap(daysRange[0], daysRange[1], (dayIndex) => {
          if (slotColumnsCache[dayIndex]) {
            return slotColumnsCache[dayIndex];
          }

          const slotColumn = observe({
            id: dayIndex,
            loading: true,
            error: null,
            slots: [],
          }, {
            deep: true,
            batch: true,
          });
          setTimeout(() => {
            requestSlots(dayIndex)
              .then((slots) =>
                runInAction(() => {
                  slotColumn.slots = slots;
                  slotColumn.loading = false;
                  slotColumn.error = null;
                }),
              )
              .catch((err) =>
                runInAction(() => {
                  console.error(err);
                  slotColumn.slots = [];
                  slotColumn.error = err;
                  slotColumn.loading = false;
                }),
              );
          }, 100);
          slotColumnsCache[dayIndex] = slotColumn;
          return slotColumn;
        });
        reactor.virtualSlotsRow.slotColumns = slotColumns;
      }

    };
  }

  function dayRangeToMonthRange(daysRange) {
    const monthsRange = [0, 0];
    monthsRange[0] = dayIndexToMonthIndex(daysRange[0]);
    monthsRange[1] = dayIndexToMonthIndex(daysRange[1]) + 1;
    return monthsRange;
  }

  function dayIndexToMonthIndex(dayIndex) {
    const d = dayIndexToDayJs(dayIndex);
    const m = d.startOf('month');
    const yearComponent = (m.year() - 1) * 12;
    const monthComponent = m.month();
    const i = yearComponent + monthComponent;
    return i;
  }

  function monthSelectOptions() {
    const options = [];

    const min = epochToScrollLeft(absoluteRange[0].unix());
    const max = epochToScrollLeft(absoluteRange[1].unix());

    let i = absoluteRange[0].startOf('month').locale(reactor.locale);
    while (true) {
      options.push({
        text: i.format('MMMM YYYY'),
        value: clamp(epochToScrollLeft(i.unix()), min, max),
      });

      i = i.add(1, 'month');
      if (absoluteRange[1].diff(i) < 0) {
        break;
      }
    }

    return options;
  }

  function makeDay(dayIndex) {
    const d = dayIndexToDayJs(dayIndex);
    const m = d.locale(reactor.locale);
    const epoch = d.unix();
    const day = {
      id: epoch,
      index: dayIndex,
      dayStartEpoch: epoch,
      friendlyDayName: m.format('dddd'),
      dayNumberString: m.format('D'),
      monthName: m.format('MMMM'),
      isWeekend: [6, 0].includes(m.day()),
    };
    return day;
  }

  function checkForDst(daySpanStart, daySpanEnd) {
    const dayLengthSeconds = (daySpanEnd - daySpanStart);
    // console.log(`dayLength in hrs`, dayLengthSeconds / 3600);
    if (dayLengthSeconds !== /* 24 hours */ 24 * 60 * 60) {
      console.warn(`this is DST change day`);
      return {
        isDst: true,
        difference: dayLengthSeconds,
      };
    } else {
      return {
        isDst: false,
      };
    }
  }

  async function requestSlots(dayIndex) {
    // console.log(`requesting for dayIndex =`, dayIndex)
    // console.log(`requestSlots`)
    const d = dayIndexToDayJs(dayIndex);
    const daySpanStart = d.startOf('day').unix();
    const daySpanEnd = d.add(1, 'day').unix();
    const daySpan = [daySpanStart, daySpanEnd];
    checkForDst(daySpanStart, daySpanEnd);
    // console.log(`daySpan`, daySpan)
    const slots = await fetchSlots(reactor.url, reactor.serviceId, daySpan);
    // console.log(`fetched ${slots.length} slots for range ${daySpan[0]}-${daySpan[1]}`);
    const selectedSlotId = reactorReactor.selected ? reactorReactor.selected.id : undefined;
    const slotObjects = slots.map((s) => {
      let text = dayjs.unix(s[0]).format('HH:mm');

      // make sure space padding at front exists so all digits are aligned
      // supports am/pm as well
      // if (text[0] === '0') {
      //   text[0] = ' ';
      // }
      const id = s[0];
      const value = s[0];
      const slotObj = observe({
        id,
        text,
        value,
        isChecked: selectedSlotId === id,
      }, {
        deep: true,
        batch: true,
      });
      slotObj.onClick = function () {
        runInAction(() => {
          const idPrev = get(reactorReactor, 'selected.id');
          const idNext = slotObj.id;
          if (idPrev !== idNext) {
            if (reactorReactor.selected) {
              reactorReactor.selected.isChecked = false;
            }
          }

          slotObj.isChecked = !slotObj.isChecked;

          if (slotObj.isChecked) {
            reactorReactor.selected = slotObj;
            reactor.onSelected(slotObj);
          } else {
            reactor.onUnselected();
          }
        });
      };

      return slotObj;
    });
    // if (!global._stop) { debugger; }
    // console.log(`requestSlots end`)
    return slotObjects;
  }
}

export default State;
