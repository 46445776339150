import { delay as delayFn, stringify } from './index';
import config from '../config.js';

function Api({ baseUrl, delay }) {

  return {
    async request({
      method,
      url,
      body,
      qs,
    }) {
      try {
        if (delay) {
          await delayFn(delay);
        }
        var u = new URL(baseUrl + '/' + url);
        if (qs) {
          Object.entries(qs).forEach(([k, v]) => {
            u.searchParams.set(k, v);
          })
        }

        var b = body ? stringify(body) : null;
        var fetchOptions = {
          method: method || 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          redirect: 'follow',
          body: b,
        }
        const response = await fetch(u, fetchOptions);
        if (!response.ok) {
          throw `unexpected status code [${response.status}] [${response.statusText}]`;
        }
        const json = await response.json();
        if (!("error" in json)) {
          throw Error(`missing error in envelope`);
        }
        if (!("value" in json)) {
          throw Error(`missing value in envelope`);
        }

        const { value, error } = json;

        if (error) {
          throw error;
        }

        return value;
      } catch (error) {
        var workingErrors;
        if (Array.isArray(error)) {
          workingErrors = error;
        } else {
          workingErrors = [error];
        }

        throw [
          ...workingErrors,
          `While fetching ${u}`,
          new Error(`See the console for details`),
        ]
      }
    },
    websocket({ }) {
      var socket = new WebSocket(config.backendApiWebsocketExternalUrl);
      return socket;
    }
  }
}

// var baseUrl = window.location.href.split('/').splice(0, 3).join('/') + '/api/v1'; /* avoid CORS */ // http://www.appoint.space:20024/sign-in 
// var baseUrl = process.env.SERVER_BASE_URL;
// var baseUrl = 'https://appoint.space/api/v1/'
const api = Api({
  // delay: 300,
  baseUrl: config.backendApiExternalUrl,
})

export default api;