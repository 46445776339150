import { minLength } from '~/utils';
import { observe, computed } from '~/utils/hyperactiv.js';

export const SCREEN_STATE = {
  TOP: 'TOP',
  CENTER: 'CENTER',
  BOTTOM: 'BOTTOM',
  PRE_RENDERED: 'PRE_RENDERED',
};

export const DIRECTION = {
  UP: 'UP',
  DOWN: 'DOWN',
}

export default function ScreenManager(schema, transitionTime = 50) {
  let renderedSlidesCounter = 0;

  const toDispose = [];

  const screens = observe({}, {
    deep: true,
    batch: true,
  });

  function enqueue(delay, id, fn) {
    setTimeout(fn, delay);
  }

  function slide(direction, id) {
    // special case
    // if first ever render
    //  - render immidiately, without slide
    if (renderedSlidesCounter === 0) {
      screens[id] = {
        id: id,
        state: SCREEN_STATE.CENTER,
      };
      renderedSlidesCounter++;
      return;
    }


    // todo add semaphore
    if (direction === DIRECTION.DOWN) {
      var TOP = SCREEN_STATE.TOP;
      var BOTTOM = SCREEN_STATE.BOTTOM;
    } else if (direction === DIRECTION.UP) {
      var TOP = SCREEN_STATE.BOTTOM;
      var BOTTOM = SCREEN_STATE.TOP;
    } else {
      throw new Error(`missing direction impl "${direction}"`)
    }

    let delay = 0;

    // appear up; pre-render
    // console.log(`${id} <- ${TOP}`);
    screens[id] = {
      id: id,
      state: TOP,
    };

    enqueue(delay += 1, id, () => {
      // move center -> bottom
      Object.values(screens).forEach(screen => {
        if (screen.state === SCREEN_STATE.CENTER) {
          // console.log(`${id} <- ${BOTTOM}`);
          screen.state = BOTTOM;
        }
      });

      enqueue(10, id, () => {
        // move up -> center
        // console.log(`${id} <- ${SCREEN_STATE.CENTER}`);
        screens[id].state = SCREEN_STATE.CENTER;
      })
    });

    enqueue(delay += transitionTime, id, () => {
      // after transition
      Object.values(screens).forEach(screen => {
        if (screen.state === BOTTOM) {
          // delete it

          // unless it is persistent
          if (schema.screens[screen.id].persistent) {
            screens[screen.id].state = SCREEN_STATE.PRE_RENDERED;
          } else {
            delete screens[screen.id];
          }
        }
      });
    });
  }

  function disposable(obs) {
    toDispose.push(obs);
  }

  disposable(computed(() => {
    if (minLength(1)(schema.screenId)) {
      slide(DIRECTION.DOWN, schema.screenId)
    }
  }))

  // observe persistent screens
  disposable(computed(() => {
    // 1. remove non persistent screens
    Object.entries(schema.screens).forEach(([screenId, screen]) => {
      const isPersistent = !!screen.persistent;

      if (screens[screenId]) {
        // ?
      } else {
        if (isPersistent) {
          screens[screenId] = {
            id: screenId,
            state: SCREEN_STATE.PRE_RENDERED,
          };
        } else {
          // should not be displayed accoring to previous code
        }
      }
    })

  }))

  return {
    slide,
    screens,
    dispose() {
      toDispose.forEach(d => d());
    }
  }
}
