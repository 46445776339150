import { Component } from "preact";
import { watchClassComponent } from '~/utils/hyperactiv';

import State from './reactor';
import { Headers as HeadersCN } from './simple/headers.css';
import { HorizontalScrollClippedContent as HorizontalScrollClippedContentCN } from './simple/horizontal-scroll-clipped-content.css';
import { PseudoTable as PseudoTableCN } from './simple/pseudo-table.css';
import VirtualDaysRow from './simple/virtual-days-row.js';
import VirtualMonthsRow from './simple/virtual-months-row.js';
import VirtualSlotsRow from './simple/virtual-slots-row.js';
import throttle, { simpleThrottle } from './utils/throttle';

import { createRef } from 'preact';

class TimeSlotPicker extends Component {
  constructor(props) {
    super(props);
    State(props.options, props.reactor, props);
    props.options.quickGoToDropdown.goToMonth = this.handleGoToMonth.bind(this);
    this.ssh = this.ssh.bind(this);

    this.scrollSync = [createRef(), createRef()];
    this.scrollSyncHandlers = this.scrollSync.map((_, i) => this.ssh.bind(this, i));

    this.handleScroll = throttle(this.handleScroll.bind(this), 500, {
      leading: true,
      trailing: true,
    });
  }

  render() {
    // console.warn('RENDERING TimeSlotPicker')
    // console.log(`component props`, this.props);

    return (
      <div class={PseudoTableCN}>
        {/* {this.renderTopRight()} */}
        {this.renderHeadersPane()}
        {this.renderBodyPane()}
      </div>
    );
  }

  renderTopRight() {
    return (
      <TStickyHead>
        <QuickGoToDropdown state={this._state.quickGoToDropdown} />
      </TStickyHead>
    );
  }

  renderHeadersPane() {
    return (
      <div class={HeadersCN} ref={this.scrollSync[0]}>
        <VirtualMonthsRow reactor={this.props.options.virtualMonthsRow} />
        <VirtualDaysRow reactor={this.props.options.virtualDaysRow} />
      </div>
    );
  }

  renderBodyPane() {
    return (
      <div class={HorizontalScrollClippedContentCN} ref={this.scrollSync[1]}>
        <VirtualSlotsRow reactor={this.props.options.virtualSlotsRow} />
      </div>
    );
  }

  ssh(idx, evt) {
    window.requestAnimationFrame(() => { /* crucial! */
      this.handleScroll();
      const opposite = idx === 0 ? 1 : 0;
      this.scrollSync[opposite].current.onscroll = null;
      this.scrollSync[opposite].current.scrollLeft = this.scrollSync[idx].current.scrollLeft;
      window.requestAnimationFrame(() => {
        this.scrollSync[opposite].current.onscroll = this.scrollSyncHandlers[opposite];
      })
    })
  }

  componentDidMount() {
    this.scrollSync[0].current.onscroll = this.scrollSyncHandlers[0];
    this.scrollSync[1].current.onscroll = this.scrollSyncHandlers[1];
  }

  componentWillUnmount() {
    this.scrollSync[0].current.onscroll = null;
    this.scrollSync[1].current.onscroll = null;
  }

  handleScroll() {
    this.props.options.handleScroll(this.scrollSync[1].current.scrollLeft, this.scrollSync[1].current.offsetWidth);
  }

  handleGoToMonth(event) {
    const px = event.target.value;
    /* smooth scrolling is perceived slow */
    // this.scrollRef.current.scrollTo({ left: px, behavior: 'smooth' })
    this.scrollSync[1].current.scrollLeft = px;
  }
}

export default watchClassComponent(TimeSlotPicker);