import clsx from '~/utils/clsx';
import { Choice as ChoiceCN, isSelected as isSelectedCN, Hotkey as HotkeyCN, isDisabled as isDisabledCN } from './choice.css';
import * as CCN from './choice.css';
import Hotkey from './hotkey.js';

export default function Choice(p) {
  return (
    <div onClick={p.onClick} class={clsx(ChoiceCN, p.isSelected && isSelectedCN, p.isDisabled && isDisabledCN)}>
      {p.hotkey ? <Hotkey hotkey={p.hotkey} cn={HotkeyCN} /> : null}
      <span class="text">{p.text}</span>
    </div>
  )
}
