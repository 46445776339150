import numberToLetter from './number-to-letter.js';
import Choice from './choice.js';
import { watchFunctionalComponent } from '~/utils/hyperactiv.js';

export default watchFunctionalComponent(function SingleChoice(p) {
  return (
    <div class="SingleChoice">
      {p.options.map((option, index) => (
        <Choice
          key={option.id}
          id={option.id}
          onClick={() => {
            if (p.reactor.selectedId === option.id) {
              p.reactor.selectedId = null;
            } else {
              p.reactor.selectedId = option.id;
            }
          }}
          isSelected={p.reactor.selectedId === option.id}
          hotkey={numberToLetter(index)}
          text={option.text}
        />
      ))}
    </div>
  )
})
