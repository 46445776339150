import jsen from 'jsen';
import { Error as ErrorCN } from './error.css';
import { Component } from "preact";
import { computed, observe, watchClassComponent } from '~/utils/hyperactiv';
import debounce from '~/utils/debounce';

class Validator extends Component {
  constructor(props) {
    super(props);
    this.weakValidate = debounce(this.weakValidate.bind(this), 1000);
    this.isValid = this.isValid.bind(this);
    this.isBlank = this.isBlank.bind(this);

    this.prevData = null;
  }

  componentDidUpdate() {
    this.props.options.isCorrect = false;

    if (this.prevData !== this.data) {
      this.props.options.error = null;
      this.weakValidate();
      this.prevData = this.data;
    }

  }

  render() {
    this.data = this.props.tools.getSchemaAt(this.props.options.dataSource);
    return <div class={ErrorCN}>{this.props.options.error}</div>;
  }

  weakValidate() {
    var blank = this.isBlank();
    if (blank) {
      // ok
    } else {
      this.isValid();
    }
  }

  isBlank() {
    if (!this.props.options.isBlank) {
      return false;
    } else {
      const v = jsen(this.props.options.isBlank);
      const result = v(this.data);
      if (result === true) {
        this.props.options.error = null;
      }
      return result
    }
  }

  isValid() {
    if (!this.props.options.isValid) {
      return true;
    }

    const validatorAndErrorContainer = jsen(this.props.options.isValid);
    validatorAndErrorContainer(this.data);
    const error = validatorAndErrorContainer.errors.find((e) => e.message);
    if (error && error.message) {
      this.props.options.error = error.message;
      this.props.options.isCorrect = false;
      return false;
    } else {
      this.props.options.error = null;
      this.props.options.isCorrect = true;
      return true;
    }
  }
}

export default watchClassComponent(Validator);
