import { observe } from '~/utils/hyperactiv';
import getSchema from '~/utils/getSchema';
import get from '~/utils/get';
import { useEffect, useState } from 'preact/hooks';
import Keynote from './keynote/keynote';
import CenterLayout from './symlinked/admin/layouts/center-layout';
import { Layout as LayoutCN } from './forms.css';

export default function Forms(props) {
  console.log(`my props`, props);
  var [state, setState] = useState('idle');
  var [schema, setSchema] = useState();

  useEffect(async () => {
    var schema = await getSchema(props.slug);
    var observableSchema = observe(schema, {
      deep: true,
      batch: true,
    })
    setSchema(observableSchema);

    if (typeof window !== 'undefined') {
      window.schema = observableSchema;
      window.get = get;
    }

    setState('loaded');
  }, []);

  if (state === 'idle') {
    return (
      <div></div>
    )
  }

  if (state === 'loaded') {
    return (
      <CenterLayout size="medium" class={LayoutCN}>
        <Keynote schema={schema} />
      </CenterLayout>
    );
  }

  throw Error(`undefined state [${state}]`)
}
