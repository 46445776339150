import hyperactiv from 'hyperactiv/src'
const { observe, computed, dispose } = hyperactiv;

export { observe, computed, dispose };

/* patch methods from hyperactiv/react */

import { createContext, Component } from 'preact';
import { useContext, useReducer, useState, useEffect } from 'preact/hooks';

export const HyperactivContext = createContext({
    store: null,
    client: null
});

export function useStore() {
    const context = useContext(HyperactivContext);
    return context && context.store
}

/**
 * Wraps a class component and automatically updates it when the store mutates.
 * @param {*} Component The component to wrap
 */
export const watchClassComponent = Component => new Proxy(Component, {
    construct: function (Target, argumentsList) {
        // Create a new Component instance
        const instance = new Target(...argumentsList)
        // Ensures that the forceUpdate in correctly bound
        instance.forceUpdate = instance.forceUpdate.bind(instance)
        // Monkey patch the componentWillUnmount method to do some clean up on destruction
        const originalUnmount =
            typeof instance.componentWillUnmount === 'function' &&
            instance.componentWillUnmount.bind(instance)
        instance.componentWillUnmount = function (...args) {
            dispose(instance.forceUpdate)
            if (originalUnmount) {
                originalUnmount(...args)
            }
        }
        // Return a proxified Component
        return new Proxy(instance, {
            get: function (target, property) {
                if (property === 'render') {
                    // Compute the render function and forceUpdate on changes
                    return computed(target.render.bind(target), { autoRun: false, callback: instance.forceUpdate })
                }
                return target[property]
            }
        })
    }
})

/**
 * Wraps a functional component and automatically updates it when the store mutates.
 * @param {*} component The component to wrap
 */
export function watchFunctionalComponent(component) {
    const wrapper = props => {
        const [, forceUpdate] = useReducer(x => x + 1, 0)
        const store = useStore()
        const injectedProps = props.store ? props : {
            ...props,
            store
        }
        const [child, setChild] = useState(null)
        useEffect(function onMount() {
            setChild(() => computed(component, {
                autoRun: false,
                callback: forceUpdate
            }))
            return function onUnmount() {
                dispose(forceUpdate)
            }
        }, [])
        return child ? child(injectedProps) : component(injectedProps)
    }
    wrapper.displayName = component.displayName || component.name
    return wrapper
}

/**
 * Wraps a component and automatically updates it when the store mutates.
 * @param {*} Component The component to wrap
 */
export const watch = Component =>
    typeof Component === 'function' ?
        watchFunctionalComponent(Component) :
        watchClassComponent(Component)

export class Watch extends Component {
    constructor(props) {
        super(props)
        this._callback = () => {
            this._mounted &&
                this.forceUpdate.bind(this)()
        }
        this.computeRenderMethod(props.render)
    }
    componentWillUnmount() {
        this._mounted = false
        dispose(this._callback)
    }
    componentDidMount() {
        this._mounted = true
    }
    computeRenderMethod(newRender) {
        if (!!newRender && this._currentRender !== newRender) {
            this._currentRender = computed(newRender, {
                autoRun: false,
                callback: this._callback
            })
        }
    }
    render() {
        const { render } = this.props
        this.computeRenderMethod(render)
        return this._currentRender && this._currentRender() || null
    }
}