import { Column as ColumnCN } from './column.css';
import { Header as HeaderCN } from './header.css';
import DayHeader from './day-header.js';
import clsx from '~/utils/clsx.js';
import { watchFunctionalComponent } from '~/utils/hyperactiv';

export default watchFunctionalComponent(function Day(p) {
  return (
    <div class={ColumnCN}>
      <div class={clsx(HeaderCN, p.reactor.isWeekend && 'weekend')}>
        <DayHeader reactor={p.reactor} />
      </div>
    </div>
  )
})