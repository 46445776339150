import Title from './title/title';
import Description from './description/description';
import SingleChoice from './single-choice/single-choice.js';
import Confirmation from './confirmation/confirmation.js';
import SingleLineTextInput from './single-line-text-input/single-line-text-input.js';
import TimeSlotPicker from './time-slot-picker/time-slot-picker.js';
import Validator from './validator/validator.js';
import PhoneNumberInput from './phone-number-input/phone-number-input.js';

import TimeSlotPickerSummary from './time-slot-picker/summary/summary.js'
import SingleChoiceSummary from './single-choice/summary';
import TextInputSummary from './single-line-text-input/summary';
import PhoneInputSummary from './phone-number-input/summary';
import Submit from './submit/submit.js';

const componentMap = {
  'title': Title,
  'description': Description,
  'confirmation': Confirmation,
  'single-choice': SingleChoice,
  'time-slot-picker': TimeSlotPicker,
  'single-line-text-input': SingleLineTextInput,
  'phone-number-input': PhoneNumberInput,

  'validator': Validator,

  'time-slot-picker-summary': TimeSlotPickerSummary,
  'single-choice-summary': SingleChoiceSummary,
  'text-input-summary': TextInputSummary,
  'phone-number-input-summary': PhoneInputSummary,

  'submit': Submit,
};


export default componentMap;