function template(str, vars, regex = /{{(.*?)}}/gm) {
    var workingStr = str;

    var match = regex.exec(str);
    while (match != null) {
        var begin = workingStr.substring(0, match.index);
        var end = workingStr.substring(match.index + match[0].length);

        var path = match[1].trim();
        var replacement = path.split('.').reduce((vars, prop, i, arr) => {
            const value = vars[prop];
            if (!value && /* and will iterate next */ i < arr.length - 1) {
                arr.splice(1); // break early
                return undefined;
            }
            return value;
        }, vars) || match[0];

        workingStr = begin + replacement + end;

        regex.lastIndex = begin.length + replacement.length - 1;
        match = regex.exec(workingStr);
    }

    return workingStr;
}

export default template;