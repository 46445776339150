import { Keynote as KeynoteCN } from './keynote.css'
import ScreenManager from "./logic/screen-manager";
import Screen from './screen';
import { Component } from 'preact';
import clsx from '~/utils/clsx';
import { watchClassComponent } from '~/utils/hyperactiv';

export default watchClassComponent(class Keynote extends Component {

    constructor(props) {
        super(props);
        this.screenManager = ScreenManager(props.schema);
    }

    componentWillUnmount() {
        this.screenManager.dispose();
    }

    render() {
        return (
            <div class={clsx('Keynote', KeynoteCN)}>
                {Object.values(this.screenManager.screens).map((screen) => {
                    return <Screen key={screen.id} id={screen.id} state={screen.state} schema={this.props.schema} />;
                })}
            </div>
        );
    }
})

